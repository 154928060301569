import { ClientHttp } from "@/services";
import { saveToken } from "@/services/storage";

type DataProps = {
  email: string,
}

type ResponseProps = {
  data: {
    message: string;
  }
};

const ReSendConfirmationFactory = (client: ClientHttp) => {
  const reSend = (email: string) => saveToken(
    client.post<DataProps, ResponseProps>('/user/re-send-confirmation', { email })
  );

  return { reSend };
}

export default ReSendConfirmationFactory;
