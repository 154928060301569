import Head from 'next/head';
import { useRouter } from 'next/router';

const defaultMeta = {
  title: 'Tuzumma',
  siteName: 'tuzumma app',
  description:
    'An platform to managment your money and your personal finances',
  url: 'https://app.tuzumma.com',
  type: 'website',
  robots: 'follow, index',
  image: 'https://app.tuzumma.com/images/large-og.png',
};


type SeoProps = {
  date?: string;
  templateTitle?: string;
} & Partial<typeof defaultMeta>;

export default function Seo(props: SeoProps) {
  const router = useRouter();
  const meta = {
    ...defaultMeta,
    ...props,
  };
  meta['title'] = props.templateTitle
    ? `${props.templateTitle} | ${meta.siteName}`
    : meta.title;


  return (
    <Head>
      <title>{meta.title}</title>
      <meta name='robots' content={meta.robots} />
      <meta content={meta.description} name='description' />
      <meta property='og:url' content={`${meta.url}${router.asPath}`} />
      <link rel='canonical' href={`${meta.url}${router.asPath}`} />

      <meta name='msapplication-TileColor' content='#ffffff' />
      <meta
        name='msapplication-TileImage'
        content='/favicon/Verde_Isotipo.png'
      />
      <meta name='theme-color' content='#ffffff' />
    </Head>
  );
}