import { ClientHttp } from "@/services";

export type Expense = {
  slug: string;
  amount: number;
  transactionDate: string;
  isRecurrent: boolean;
  paymentMethod: "credit" | "card" | "cash";
  id: string;
  expenseCategory: {
    id: string;
    name: string;
    description: string;
    hexColor: string;
  }
}

type ResponseProps = {
  data: {
    message?: string;
    updated?: boolean;
    deleted?: boolean;
  }
};

type GetExpenseResponse = {
  data: {
    expense: Expense,
  }
}

export type GetExpenseCategory = {
  id: string
  name: string
  description: string
  hexColor: string
  iconUrl: string
  total: number
}

type GetExpenseCategoryResponse = {
  data: {
    expenses: GetExpenseCategory[]
  }
}

type GetExpensesByCategoryResponse = {
  data: {
    expenses: Expense[]
    currentMonth: string
    totalExpensesByCurrentMonth: number
    expenseCategory: GetExpenseCategory
  }
}

export type GetExpensesResponse = {
  data: {
    expenses: Expense[];
    totalExpensesByCurrentMonth: number;
    currentMonth: string;
    month: number;
    year: number;
  }
}

type GetExpensesProps = {
  month: number;
  year: number;
}

type GetExpensesCategoryProps = {
  month: number;
  year: number;
}

type GetSummaryResponse = {
  data: {
    totalExpenses: number;
  }
}

type CreateExpenseProps = {
  slug: string
  amount: number
  transactionDate: string
  category: string
  paymentMethod: string
  isRecurrent: boolean
}

type UpdatedExpenseProps = {
  expenseId: string;
} & Partial<CreateExpenseProps>;

const ExpenseFactory = (client: ClientHttp) => {
  const createExpense = (props: CreateExpenseProps) =>
    client.post<CreateExpenseProps, ResponseProps>('/expense/create-expense', props);

  const updateExpense = (props: UpdatedExpenseProps) =>
    client.put<UpdatedExpenseProps, ResponseProps>('/expense/update-expenses', props);

  const getExpenses = () => client.get<GetExpensesResponse>('/expense/get-expenses', { params: { expenses: true } });

  const getExpensesByMonthAndYear = ({ month, year }: { month: number, year: number }) =>
    client.post<GetExpensesProps, GetExpensesResponse>('/expense/get-expenses', { month, year });

  const getExpense = (expenseId: string) => () => client.get<GetExpenseResponse>('/expense/get-expense', { params: { expenseId } });

  const getExpenseCategory = () => client.get<GetExpenseCategoryResponse>('/expense/get-expenses-category');

  const getExpenseCategoryByMonthAndYear = ({ month, year }: { month: number, year: number }) =>
    client.post<GetExpensesCategoryProps, GetExpenseCategoryResponse>('/expense/get-expenses-category', { month, year });

  const deleteExpense = (expenseId: string) => client.delete('/expense/delete-expense', { data: { expenseId } })

  const getSummary = () => client.get<GetSummaryResponse>('/expense/get-summary');

  const getExpensesByCategory = ({ categoryName, month, year }: {categoryName: string, month: number, year: number }) => () => client.get<GetExpensesByCategoryResponse>('/expense/get-expenses-by-category', { params: { categoryName, month, year } });

  return {
    createExpense,
    updateExpense,
    getExpenses,
    deleteExpense,
    getSummary,
    getExpense,
    getExpenseCategory,
    getExpensesByCategory,
    getExpensesByMonthAndYear,
    getExpenseCategoryByMonthAndYear,
  };
}

export default ExpenseFactory;