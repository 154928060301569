/* eslint-disable react-hooks/rules-of-hooks */
import { useRouter } from "next/router";
import { useEffect } from "react";

import { getToken } from "@/services/storage"

const WithAuth = (Component: () => JSX.Element) => {
  const validateCookie = () => {
    const router = useRouter();

    useEffect(() => {
      const token = getToken();

      if (token) {
        router.push('/dashboard');
      }
    }, [router]);


    return <Component />;
  }
  return validateCookie;
};

export default WithAuth;
