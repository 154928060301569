import { ClientHttp } from "@/services";

type ResponseProps = {
  data: {
    message: string;
    id: string;
  }
};

type SaveProfileStepProps = {
  step: string;
  response: string;
  investProfileId: string;
}

type UpdatePersonalDataProps = {
  birthdate: string;
  gender: string;
  fullname: string;
}

type UpdatePersonalDataResponse = {
  data: {
    updated: boolean,
  }
}

type GetProfileScoreResponse = {
  data: {
    score: number,
    profile: 'conservador' | 'arriesgado' | 'moderado',
  }
}

type GetWaitingListResponse = {
  data: {
    id: number;
  }
}

type SimpleSimulationResponse = {
  data: {
    monthlyPayment: number;
    months: number;
  }
};

interface SimpleSimulationProps {
  amountGoal: number
  finishDateGoal: string
};

const InvestFactory = (client: ClientHttp) => {
  const createProfile = () => client.post<unknown, ResponseProps>('/invest/create-profile')
  const saveProfileStep = ({ step, response, investProfileId }: SaveProfileStepProps) =>
    client.post<SaveProfileStepProps, ResponseProps>('/invest/save-profile-step', { step, response, investProfileId });

  const updatePersonalData = ({ birthdate, gender, fullname }: UpdatePersonalDataProps) =>
    client.post<UpdatePersonalDataProps, UpdatePersonalDataResponse>('/invest/update-personal-data', {
      birthdate, gender, fullname
    });

  const getProfileScore = () => client.get<GetProfileScoreResponse>('/invest/get-profile-score');

  const getWaitingList = () => client.get<GetWaitingListResponse>('/invest/get-waiting-list');

  const simpleSimulation = ({ amountGoal, finishDateGoal }: SimpleSimulationProps) =>
    client.post<SimpleSimulationProps, SimpleSimulationResponse>('/invest/simple-simulation', {
      amountGoal, finishDateGoal
    });

  return { simpleSimulation, createProfile, saveProfileStep, updatePersonalData, getProfileScore, getWaitingList, };
}

export default InvestFactory;