import { ClientHttp } from "@/services";

type DataProps = {
  email: string
}

type ChangePasswordPayload = {
  code: string
  password: string
};

type ResponseProps = {
  data: {
    message: string
  }
};

const RecoveryPasswordFactory = (client: ClientHttp) => {
  const recovery = ({ email }: { email: string }) => client.post<DataProps, ResponseProps>('/user/recovery', { email })
  const changePassword = ({ code, password }: { code: string, password: string }) =>
    client.post<ChangePasswordPayload, ResponseProps>('/user/change-password', { code, password })

  return { recovery, changePassword };
}

export default RecoveryPasswordFactory;