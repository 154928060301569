import Axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

import ExpenseFactory from '@/services/expense';
import IncomeFactory from '@/services/income';
import InvestFactory from '@/services/invest';
import ReSendConfirmationFactory from '@/services/re-send-confirmation';
import RecoveryPasswordFactory from '@/services/recovery';
import SignInFactory from '@/services/sign-in';
import SignUpFactory from '@/services/sign-up';
import { getToken } from "@/services/storage";

const UNAUTHORIZED_STATUS_CODE = 401;

export type ClientHttp = {
  post: <D, R>(url: string, data?: D | undefined, config?: AxiosRequestConfig<unknown> | undefined) => Promise<R>
  put: <D, R>(url: string, data?: D | undefined, config?: AxiosRequestConfig<unknown> | undefined) => Promise<R>
  get: <R>(url: string, config?: AxiosRequestConfig<unknown> | undefined) => Promise<R>
  delete<T = unknown, R = AxiosResponse<T>, D = unknown>(url: string, config?: AxiosRequestConfig<D>): Promise<R>;
}

const axios = Axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL,
});

axios.interceptors.request.use(
  (config: AxiosRequestConfig<unknown>) => {
    const token = getToken();

    if (!token) {
      return config;
    }

    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${token.toString()}`,
    }

    return config;
  }, error => Promise.reject(error)
);

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error.response
      && (
        (
          error.response.data
          && error.response.data.message
          && error.response.data.message !== 'WRONG_PASSWORD'
        ) || !error.response.data
      )
      && (
        error.response.status === UNAUTHORIZED_STATUS_CODE ||
        error.response.status === 0
      )
      && process.env.NEXT_PUBLIC_DOMAIN
    ) {
      location.href = process.env.NEXT_PUBLIC_DOMAIN.toString();
    }
    return Promise.reject(error);
  }
)

export const signInService = SignInFactory(axios);
export const signUpService = SignUpFactory(axios);
export const reSendConfirmation = ReSendConfirmationFactory(axios);
export const incomeService = IncomeFactory(axios);
export const expenseService = ExpenseFactory(axios);
export const investService = InvestFactory(axios);
export const recoveryPasswordService = RecoveryPasswordFactory(axios);
