import { ClientHttp } from "@/services";

export type Income = {
  id: string;
  slug: string;
  amount: number;
  transactionDate: string;
  isRecurrent: boolean;
  paymentMethod: "credit" | "card" | "cash";
}

type GetIncomeResponse = {
  data: {
    income: Income;
  }
}

type ResponseProps = {
  data: {
    message?: string;
    updated?: boolean;
    deleted?: boolean;
  }
};

type GetIncomesResponse = {
  data: {
    incomes: Income[];
    totalIncomeByCurrentMonth: number;
    currentMonth: string;
    month: number;
    year: number;
  }
}

type GetIncomesProps = {
  month: number;
  year: number;
}


type GetSummaryResponse = {
  data: {
    totalIncomes: number;
  }
}

type CreateIncomeProps = {
  slug: string
  amount: number
  paymentMethod: string
  isRecurrent: boolean
  transactionDate: string
}

type CreateIncomeDataProps = {
  slug: string
  amount: number
  paymentMethod: string
  isRecurrent: boolean
  transactionDate: string
  category: string
}

type UpdatedIncomeProps = {
  incomeId: string;
} & Partial<CreateIncomeProps>;

const IncomeFactory = (client: ClientHttp) => {
  const createIncome = (props: CreateIncomeProps) =>
    client.post<CreateIncomeDataProps, ResponseProps>('/income/create-income', { category: 'otros', ...props});

  const updateIncome = (props: UpdatedIncomeProps) =>
    client.put<UpdatedIncomeProps, ResponseProps>('/income/update-income', props);

  const getIncomes = () => client.get<GetIncomesResponse>('/income/get-incomes', { params: { incomes: true } });

  const getIncomesByMonthAndYear = ({ month, year }: { month: number, year: number }) =>
    client.post<GetIncomesProps, GetIncomesResponse>('/income/get-incomes', { month, year });

  const getIncome = (incomeId: string) => () => client.get<GetIncomeResponse>('/income/get-income', { params: { incomeId } });

  const deleteIncome = (incomeId: string) => client.delete('/income/delete-income', { data: { incomeId } })

  const getSummary = () => client.get<GetSummaryResponse>('/income/get-summary');

  return { createIncome, updateIncome, getIncomes, deleteIncome, getSummary, getIncome, getIncomesByMonthAndYear, };
}

export default IncomeFactory;