import { ClientHttp } from "@/services";
import { saveToken } from "@/services/storage";

type DataProps = {
  email: string,
  password: string,
}

type ResponseProps = {
  data: {
    id: number;
    token: string;
  }
};

const SignInFactory = (client: ClientHttp) => {
  const signIn = ({ email, password }: { email: string, password: string }) => saveToken(
    client.post<DataProps, ResponseProps>('/user/sign-in', { email, password })
  );

  return { signIn };
}

export default SignInFactory;
