import { ClientHttp } from "@/services";

type DataProps = {
  email: string,
  password: string,
}

type ResponseProps = {
  data: {
    id: number;
  }
};

const SignUpFactory = (client: ClientHttp) => {
  const signUp = ({ email, password }: { email: string, password: string }): Promise<ResponseProps> =>
    client.post<DataProps, ResponseProps>('/user/sign-up', { email, password });

  return { signUp };
}

export default SignUpFactory;